/** @jsxImportSource @emotion/react */
import { apiClient } from "../../services/apiClient";
import Alliances from "./alliances";
import { Box, Typography } from "@mui/material";

import DeclarationModal from "./declarationModal";
import { useUser } from "../../hooks/useUser";
import { permissionService } from "../../services/permissionService";
import { getButtonSend } from "./utils";
import { TradeAlliance } from "../../my-api-client";
import React from "react";

export default function SubpoenaDeclarations() {
  const user = useUser();
  return (
    <Box  sx={{
        textAlign: 'center'
    }}>
      <DeclarationModal
        listCall={apiClient().allSubpoenasList.bind(apiClient())}
        allianceCreate={apiClient().allSubpoenasCreate.bind(apiClient())}
        allianceTypeTitle={"Send Subpoena"}
        allianceTypeText={"send a subpoena to"}
        disabled={permissionService.canInitiateSubpoena(user)}
      />
      <Alliances
        listCall={apiClient().allSubpoenasList.bind(apiClient())}
        buttonMapSend={getButtonSend(user, "Subpoena", "Subpoena")}
        buttonMapReceive={(alliance: TradeAlliance) => {
          return {};
        }}
        customStatusMap={
          {
              SENDER_ACCEPTED: "Delivered"
          }
        }
      ></Alliances>
    </Box>
  );
}
