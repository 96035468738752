import {Avatar} from "@mui/material";
import {string} from "yup";
import React from "react";

interface NationImageProps {
    src: string | undefined | null,
    style?: React.CSSProperties,
    width?: string,
    height?: string,
    css?: any
}

export default function NationImage({src, ...props}: NationImageProps) {
    return (
        src && (
            <img alt="flag" src={src || ""} width="75px" height={"50px"} {...props} />
        )
    );
}
