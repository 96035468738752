/** @jsxImportSource @emotion/react */
import useSWR, { Fetcher } from "swr";
import { apiClient } from "../../services/apiClient";
import { Item } from "../../my-api-client";
import Grid from '@mui/material/Grid2';
import { css } from "@emotion/react";
import StoreItem from "./storeItem";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import React from "react";
import { useUser } from "../../hooks/useUser.ts";
import JoyrideWrapper from "../Joyridewrapper";

export default function Military() {
  const user = useUser();
  const itemsFetcher: Fetcher<Item[]> = () => {
    return apiClient()
      .allItemsList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: items2, error } = useSWR<Item[], Error>(
    apiClient().allItemsList.name,
    itemsFetcher,
  );

  if (error) return "Error loading nations.";

  const items = items2?.filter((item) => item.isMilitary);

  if (!items) {
    return <LoadingScreen />;
  }
  const steps = [
    {
      target: '#strength',
      content: 'Each military has a strength which determines how many dice it will role when attacking or defending. ' +
          'Military items can move and attack once per day',
    },
    {
      target: '#movement_speed',
      content: 'This item can move this many hexagons per day.',
    },
  ];

  return (
      <>
        <JoyrideWrapper id={'military_ride'} steps={steps} />


    <Grid
      container
      spacing={1}
      css={css`
        margin: 10px;
      `}
    >
      {items.map((item) => {
        return <StoreItem item={item} key={item.id} />;
      })}
    </Grid>
      </>
  );
}
