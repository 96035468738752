/** @jsxImportSource @emotion/react */
import useSWR, { Fetcher } from "swr";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TradeAlliance } from "../../my-api-client";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Stack,
} from "@mui/material";
import { Shield } from "@mui/icons-material";
import NationImage from "../Nation/nationImage";
import { useUser } from "../../hooks/useUser";
import GeoWrapperTooltip from "../GeoWrapperTooltip";
import React, { ReactElement } from "react";
import { css } from "@emotion/react";
import Typography from "@mui/material/Typography";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import ExecuteButton from "./executeButton.tsx";
import DeclarationView from "./declarationView.tsx";


interface AlliancesInputProps {
  listCall: (
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance[], any>>;
  buttonMapSend: any;
  buttonMapReceive: any;
  customStatusMap?: any;
}

export default function Alliances({
  listCall,
  buttonMapSend,
  buttonMapReceive,
    customStatusMap,
}: AlliancesInputProps) {
  const [open, setOpen] = React.useState(false);
  const user = useUser();
  const tradeAlliancesFetcher: Fetcher<TradeAlliance[]> = () => {
    return listCall().then((res) => {
      return res.data;
    });
  };

  const { data: alliances, error, isLoading } = useSWR<TradeAlliance[], Error>(
    listCall.name.replace("bound ", ""),
    tradeAlliancesFetcher,
  );

  if (error) return <>"Error loading trade alliances."</>;

  const statusMap: { [name: string]: string } = Object.assign({
    CREATED: "Awaiting approval before sending",
    SENDER_ACCEPTED: "Awaiting Acceptance",
    RECEIVER_ACCEPTED: "Signed on [date]",
    SENDER_REJECTED: "Rejected by [confirmationUser]",
    RECEIVER_DECLINED: "Declined by [opposingUser]",
    SENDER_CANCELED: "Canceled by [confirmationUser]",
    RECEIVER_CANCELED: "Canceled by [opposingUser]",
    SENDER_PEACE_PROPOSED: "Awaiting Peace Acceptance",
    SENDER_PEACE_DECLINED: "Signed on [date]",
    SENDER_PEACE_ACCEPTED: "Sent",
    RECEIVER_PEACE_PROPOSED: "Awaiting Peace Acceptance",
    RECEIVER_PEACE_DECLINED: "Signed on [date]",
    RECEIVER_PEACE_ACCEPTED: "Sent",
    SENDER_SURRENDER_PROPOSED: "Awaiting Surrender Acceptance",
    SENDER_SURRENDER_DECLINED: "Signed on [date]",
    SENDER_SURRENDER_ACCEPTED: "Surrendered",
    RECEIVER_SURRENDER_PROPOSED: "Awaiting Surrender Acceptance",
    RECEIVER_SURRENDER_DECLINED: "Signed on [date]",
    RECEIVER_SURRENDER_ACCEPTED: "Surrendered",
  }, customStatusMap || {});

  const getStatus = (alliance: TradeAlliance) => {
    return <>{(statusMap[alliance.status!] || "").replace("[confirmationUser]", alliance.confirmationUser).replace("[opposingUser]",
        alliance.opposingUser).replace("[date]", new Date(alliance.createdAt).toLocaleDateString())
    }</>;
  }

  return (
    <Box>
      <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" sx={{
        justifyContent: 'center'
      }}>
        {isLoading &&
            <Box sx={{ pt: 2, pb: 2 }}>
              <LoadingScreen />
              <hr />
            </Box>
        }
        {alliances && alliances.map((item: TradeAlliance) => {
          return (
            <div key={item.id}>
              <Card sx={{ width: 300, position: "relative" }}>
                <CardContent sx={{ padding: 0, paddingBottom: "8px" }}>
                  <Box sx={{ height: '200px', marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                    <NationImage src={item.offeringNation.imageUrl} width={'300px'} height={'200px'}
                                 css={css`
                      position: absolute;
                                   margin-top: -1px;
                                   margin-left: -1px;// the margin prevents overlap from appearing on the other image
                    `}
                    />
                    <NationImage src={item.receivingNation.imageUrl} width={'300px'} height={'200px'} css={css`
                      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
                    `}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "40%",
                        marginRight: "10px",
                      }}
                    >
                      <Typography sx={{ fontSize: 15, textAlign: 'end' }}>
                        {item.offeringNation.name}
                      </Typography>
                    </Box>

                    <Shield></Shield>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "40%",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography sx={{ fontSize: 15, textAlign: 'start' }} >
                        {item.receivingNation.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{ mt: 1.5, justifyContent: "center", display: "flex" }}
                  >
                    <DeclarationView item={item} />
                  </Box>

                  <Box
                    sx={{ mt: 1.5, justifyContent: "center", display: "flex" }}
                  >
                    <Typography>{getStatus(item)}</Typography>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {item.offeringNationId === user?.nation.id &&
                    buttonMapSend(item)[item.status!]?.map((item: any) => {
                      return (
                          <ExecuteButton item={item} key={item.text} />
                      );
                    })}
                  {item.receivingNationId === user?.nation.id &&
                    buttonMapReceive(item)[item.status!]?.map((item: any) => {
                      return (
                          <ExecuteButton item={item} key={item.text} />
                      );
                    })}

                  {item.isSecret ? (
                    <GeoWrapperTooltip title={"This is a secret alliance"}>
                      <LockIcon
                        sx={{
                          position: "absolute",
                          right: "5px",
                          bottom: "5px",
                        }}
                      />
                    </GeoWrapperTooltip>
                  ) : (
                    <GeoWrapperTooltip title={"This is a public alliance"}>
                      <LockOpenIcon
                        sx={{
                          position: "absolute",
                          right: "5px",
                          bottom: "5px",
                        }}
                      />
                    </GeoWrapperTooltip>
                  )}
                </CardActions>
              </Card>
            </div>
          );
        })}
      </Stack>
    </Box>
  );
}
