/** @jsxImportSource @emotion/react */
import { Button, IconButton, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { myMutate, useUser } from "../../hooks/useUser";
import { permissionService } from "../../services/permissionService";
import { apiClient } from "../../services/apiClient.ts";
import ArticleForm from "./articleForm.tsx";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import PostAddIcon from '@mui/icons-material/PostAdd';

export default function NewArticle(props: {
  ogTitle: string;
  ogContent: string;
}) {
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const ogContent = props.ogContent;
  const ogTitle = props.ogTitle;
  const [editing, setEditing] = useState(false);

  const onSave = async (title: string, content: string) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);

    apiClient()
      .allArticlesCreate({ title: title, content: content })
      .then((res) => {
        enqueueSnackbar("Article Published", { variant: "success" });
        setEditing(false);
        myMutate(apiClient().allArticlesList.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error creating article: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  const onCancel = () => {
    setEditing(false);
  };

  return (
    <>
      {permissionService.canPublishNews(user).success ? (
        <>
          {!editing ? (
            <IconButton
                sx={{ float: "right" }}
                color={"bland"}
              onClick={() => {
                setEditing(true);
              }}
            >
              <PostAddIcon />
            </IconButton>
          ) : (
            <>
              <ArticleForm
                ogTitle={ogTitle}
                ogContent={ogContent}
                onCancel={onCancel}
                onSave={onSave}
                saving={saving}
              />
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
