import * as React from "react";
import { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import useSWR, {mutate} from "swr";
import { UserClasses } from "../../../my-api-client";
import { apiClient } from "../../../services/apiClient";
import { useNavigate } from "react-router-dom";
import { permissionService } from "../../../services/permissionService";
import Cookies from "js-cookie";
import { enqueueSnackbar } from "notistack";
import useLogoutZulip from "../../../hooks/useLogoutZulip.ts";
import useLogout from "../../../hooks/useLogout.tsx";
import useLogoutAdmin from "../../../hooks/useLogoutAdmin.tsx";

export default function AccountMenu() {
    const logoutZulip = useLogoutZulip();
    const logout = useLogout();
  const logoutAdmin = useLogoutAdmin();
  const navigate = useNavigate();

  const user = useUser();
  const { data: userClasses, error } = useSWR<UserClasses[], Error>(
    apiClient().allUserClassesList.name,
    function () {
      return apiClient()
        .allUserClassesList()
        .then((res) => {
          return res.data;
        });
    },
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [accountId, setAccountId] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAccountId(event.target.value);

    apiClient()
      .allUserClassesSwitchAccountCreate(parseInt(event.target.value))
      .then((res) => {
          Promise.all([logoutZulip(), logoutAdmin()]).then(() => {
              // this causes errors when switching
             // mutate(() => true, undefined, false).then(() => {
                  window.location.reload();
           //   });
          });
        });
  };

  useEffect(() => {
    setAccountId(user.account?.toString());
  }, [user]);

  return (
    <React.Fragment>
      <Tooltip title="Account settings">
        <IconButton
            id="account-menu-icon"
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }} src={user.imageUrl} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {userClasses && userClasses?.length > 1 && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Current Class</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={accountId}
              label="Current Account"
              onChange={handleChange}
            >
              {userClasses &&
                userClasses?.map((userClass) => {
                  return (
                    <MenuItem
                      value={userClass.account.id}
                      key={userClass.account.id}
                    >
                      {userClass.account.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
        <MenuItem
          onClick={() => {
            navigate("/");
            handleClose();
          }}
        >
          Home
        </MenuItem>
        {user && permissionService.isAdmin(user).success && (
            <MenuItem
                onClick={() => {
                  navigate("/app/getting_started");
                  handleClose();
                }}
            >
              Getting Started
            </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            navigate("/app/profile/0");
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/app/guide");
            handleClose();
          }}
        >
          User Guide
        </MenuItem>
          <MenuItem
              onClick={() => {
                  navigate("/app/suggestions");
                  handleClose();
              }}
          >
              Wishlist
          </MenuItem>
          {/*<MenuItem*/}
          {/*    onClick={() => {*/}
          {/*        navigate("/app/changelog");*/}
          {/*        handleClose();*/}
          {/*    }}*/}
          {/*>*/}
          {/*    Changelog*/}
          {/*</MenuItem>*/}
        {/*{user && permissionService.isAdmin(user).success && (*/}
        {/*  <MenuItem*/}
        {/*    onClick={() => {*/}
        {/*      navigate("/app/lesson_plan");*/}
        {/*      handleClose();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Lesson Plan*/}
        {/*  </MenuItem>*/}
        {/*)}*/}
        {user && permissionService.isAdmin(user).success && (
            <MenuItem
                onClick={() => {
                  navigate("/admin");
                  handleClose();
                }}
            >
              Admin
            </MenuItem>
        )}
        {/*{user && permissionService.isAdmin(user).success && (*/}
        {/*  <MenuItem*/}
        {/*    onClick={async () => {*/}
        {/*      const res = await fetch(*/}
        {/*        `${import.meta.env.VITE_NODE}/api/billingRedirect/`,*/}
        {/*        {*/}
        {/*          // @ts-ignore*/}
        {/*          headers: {*/}
        {/*            "Content-Type": "application/json",*/}
        {/*            "X-CSRFToken": Cookies.get("csrftoken"),*/}
        {/*          },*/}
        {/*          credentials: "include",*/}
        {/*        },*/}
        {/*      ).then((res) => {*/}
        {/*        return res.json();*/}
        {/*      });*/}

        {/*      if (res.url) {*/}
        {/*        window.location.href = res.url;*/}
        {/*      } else {*/}
        {/*        enqueueSnackbar("Error opening billing portal", {*/}
        {/*          variant: "error",*/}
        {/*        });*/}
        {/*        console.error("unable to open billing portal");*/}
        {/*      }*/}
        {/*      handleClose();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Billing Portal*/}
        {/*  </MenuItem>*/}
        {/*)}*/}
        {/*
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
          */}
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
