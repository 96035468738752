// @ts-nocheck
import * as React from "react";
import WelcomePage from "../components/Waitlist";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function Landing(props: Props) {

  return (
      <WelcomePage />
  );
}
