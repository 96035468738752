/** @jsxImportSource @emotion/react */
import useSWR, { Fetcher } from "swr";
import { apiClient } from "../../services/apiClient";
import {Item, Purchase, PurchaseStatusEnum} from "../../my-api-client";
import Grid from '@mui/material/Grid2';
import { css } from "@emotion/react";
import StoreItem from "./storeItem";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import {useUser} from "../../hooks/useUser.ts";
import JoyrideWrapper from "../Joyridewrapper";
import React from "react";

export default function Development() {
  const user = useUser();
  const itemsFetcher: Fetcher<Item[]> = () => {
    return apiClient()
      .allItemsList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: items2, error } = useSWR<Item[], Error>(
    apiClient().allItemsList.name,
    itemsFetcher,
  );

  if (error) return "Error loading nations.";

  const items = items2?.filter((item) => !item.isMilitary);


  const purchaseFetcher: Fetcher<Purchase[]> = () => {
    return apiClient()
        .allPurchasesList()
        .then((res) => {
          return res.data;
        });
  };

  const {
    data: purchases2,
    error: error2,
    isLoading,
  } = useSWR<Purchase[], Error>(
      apiClient().allPurchasesList.name,
      purchaseFetcher,
  );

  const purchases = purchases2?.filter(
      (item) => item.controlledBy.id == user.nation.id && (item.status == PurchaseStatusEnum.PurchasePlaced || item.status == PurchaseStatusEnum.Created || item.status == PurchaseStatusEnum.SenderAccepted),
  );

  // this is kind of a jank way to show powerpoints properly
  // Create a map to count purchases for each item id
  const purchaseCountMap = purchases?.reduce((acc, purchase) => {
    const purchaseCount = acc?.[purchase.itemId] || 0;
    // @ts-ignore
    purchase.points = Math.max(purchase.item.points - purchaseCount, 0)
    acc[purchase.itemId] = (acc[purchase.itemId] || 0) + 1;
    return acc;
  }, {} as Record<number, number>);

  // Adjust item powerpoints based on purchase count
  const adjustedItems = items?.map((item) => {
    const purchaseCount = purchaseCountMap?.[item.id] || 0;
    return {
      ...item,
      points: Math.max(item.points - purchaseCount, 0),
    };
  });

  if (!items) {
    return <LoadingScreen />;
  }

  const steps = [
    {
      target: '.infrastructure_header',
      content: 'You can use resources to purchase various items to develop your nation.',
    },
    {
      target: '.military_header',
      content: 'Once you have a military base you can also purchase military items.',
    },
    {
      target: '.pending_header',
      content: 'Once a member of your nation purchases an item it still needs to be approved.',
    },
    {
      target: '#placement',
      content: 'Items may only be placed on specific titles',
    },
    {
      target: '#power_points',
      content: 'Each item will give a different amount of power points.  This number decreases for each additional asset owned of the same type',
    },
    {
      target: '#daily_cost',
      content: 'In addition to the development cost some items include a cost which will be deducted from your resources 2 days a week.',
    },

  ];
  if (user.isAdmin) {
    steps.push({
      target: '#gift',
      content: 'As an admin you can gift an item which will allow the nation to place it on the map immediately',
    });
  }

  return (<>
    <JoyrideWrapper id={'development_ride'} steps={steps} />

    <Grid
      container
      spacing={1}
      css={css`
        margin: 10px;
      `}
    >
      {adjustedItems?.map((item) => {
        return <StoreItem item={item} key={item.id} />;
      })}
    </Grid>
      </>
  );
}
