import NationContent from "./nationContent";
import useSWR, { Fetcher } from "swr";
import { useParams } from "react-router-dom";
import { apiClient } from "../../services/apiClient.ts";
import { Nations, NationsStat } from "../../my-api-client";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import React from "react";
import Joyride from "react-joyride";

export default function Nation() {
  const { id } = useParams();
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [resourcesOpen, setResourcesOpen] = React.useState(false);
  const [statsOpen, setStatsOpen] = React.useState(false);
  const [powerOpen, setPowerOpen] = React.useState(false);

  const nationFetcher: Fetcher<Nations> = () => {
    return apiClient()
      .allNationsRetrieve(parseInt(id || "0"))
      .then((res) => {
        return res.data;
      });
  };

  const { data, error } = useSWR<Nations, Error>(
    apiClient().allNationsRetrieve.name,
    nationFetcher,
  );

  const { data: stats, error: error2 } = useSWR<NationsStat, Error>(
    apiClient().allNationStatsRetrieve.name,
    function () {
      return apiClient()
        .allNationStatsRetrieve()
        .then((res) => {
          return res.data;
        });
    },
  );

  const statDict = {};
  for (const stat of stats?.nations || []) {
    // @ts-ignore
    statDict[stat.id] = stat;
  }

  if (error) return "Error loading nation.";

  if (!data) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <NationContent
          index={0}
        nation={data}
        stats={
          // @ts-ignore
          statDict[id] || {}
        }

          adminOpen={adminOpen}
        setAdminOpen={setAdminOpen}
        resourcesOpen={resourcesOpen}
        setResourcesOpen={setResourcesOpen}
        statsOpen={statsOpen}
        setStatsOpen={setStatsOpen}
        powerOpen={powerOpen}
        setPowerOpen={setPowerOpen}
      />
    </div>
  );
}
