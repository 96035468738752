/** @jsxImportSource @emotion/react */

import { myMutate, useUser } from "../../hooks/useUser";

import React, { useState } from "react";
import { permissionService } from "../../services/permissionService";
import Link from "../link";
import { Nations } from "../../my-api-client";
import { apiClient } from "../../services/apiClient.ts";
import {
    BoxProps,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import Box from "@mui/material/Box";

interface NationNameProps extends BoxProps {
    nation: Nations }

export default function NationName({ nation, ...props }: NationNameProps) {
  const user = useUser();
  const [editing, setEditing] = React.useState(false);

  const [saving, setSaving] = useState(false);
  const [originalName, setOriginalName] = useState(nation.name || "");
  const [name, setName] = useState(nation.name || "");

  const save = async () => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    const res = await apiClient()
      .allNationsPartialUpdate(nation.id, { name: name })
      .then((res) => {
        enqueueSnackbar("Name Saved", {
          variant: "success",
        });
        myMutate(apiClient().allNationsRetrieve.name);
        myMutate(apiClient().allNationsList.name);
        setOriginalName(res.data.name || "");
        setEditing(false);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error saving name: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
        setSaving(false);
      });
  };

  const cancel = async () => {
    setName(nation.name || "");
    setEditing(false);
  };

  return (
    <Box sx={{ display: "flex", alignItems: 'center' }}  {...props}>
      <Link href={`/app/nations/${nation.id}`}>
        <Typography variant="h5" component="h2">
          {nation.name}
        </Typography>
      </Link>
      {permissionService.canEditConstitution(user, nation.id).success ? (
        <EditIcon
          sx={{ cursor: "pointer", fontSize:"16px", marginLeft: '10px', color: '#2f2f2f' }}
          onClick={() => {
            setEditing(true);
          }}
        />
      ) : (
        ""
      )}

      <Dialog open={editing} onClose={cancel}>
        <DialogTitle>Nation</DialogTitle>
        <DialogContent>
          <DialogContentText>Update your nations name</DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label=""
            fullWidth
            variant="standard"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel} disabled={saving}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={save} disabled={saving}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
