import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "../link";

const GettingStarted = () => {
  return (
    <Container maxWidth="lg">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Following this guide will help you with everything needed to get started with running your first class.
            This guide is a work in progress. Below is a brief list of actions you may expect to take as an
            administrator of a class.
          </Typography>
          <ul>
            <li>Create a class</li>
            <li>Add students to the class.  Including determining which nation each student will belong to and what their role will be.</li>
            <li>Define each nation's territories</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h6">Creating Your First Class</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can visit <Link
              sx={{ textDecoration: 'underline' }}
              href={`/admin/classes`}
          >
            the admin site
          </Link>, which is available from the user menu in the top right of the app, to view and manage your classes. On this page you can create new classes.
            After creating a new class the 4 nations for that class, (Ag, Lumber, Oil, and Mineral) will all automatically be created.
            The next step would be to add users to that class.
          </Typography>
          <br></br>
          <img src={"/create_class.gif"} alt="Create Class" width={"100%"} />
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Adding Users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            On the <Link
              href={`/admin/users`}
              sx={{textDecoration: 'underline'}}
          >
            users admin page
          </Link> you can add your students to your classes. Students can be added individually or by uploading a csv
            file.
            Clicking "EXAMPLE IMPORT FILE" will output an example file of how the csv import should look.
            A "1" is used for each cell to indicate which roles and team that user should be on.
            For example the user john@example.com will have a "1" in the prime_minister cell to indicate he is a prime
            minister
            and in the ag cell to indicate he is a part of the ag nation.
          </Typography>
          <br></br>
          <img src={"/bulk_add_users.gif"} alt="Bulk Add Users" width={"100%"}/>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h6">Defining territory</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            On the <Link
              href={`/app/map`}
              sx={{ textDecoration: 'underline' }}
          >
            map page
          </Link> you can can define the territories for each nation. It's recommended to have the students do this as a
            class activity.
            On the map page in the menu you can select the territory option.
            Using this menu you can select which nation you are adding territory for and then click and drag to add to
            their territory.
            Once you have added the territory you should "Confirm" to save the changes
          </Typography>
          <br></br>
          <img src={"/set_territory.gif"} alt="Set Territory" width={"100%"}/>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default GettingStarted;
