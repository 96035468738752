/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl, IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import NationDropdown from "../NationDropdown";
import { myMutate, useUser } from "../../hooks/useUser";
import useSWR, { Fetcher } from "swr";
import {
  Nation,
  TradeAlliance,
  TradeAllianceRequest,
  TradeStatusStatusEnum,
} from "../../my-api-client";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { closeSnackbar, enqueueSnackbar } from "notistack";
import {apiClient} from "../../services/apiClient.ts";
import GeoButton from "../GeoButton";
import PostAddIcon from "@mui/icons-material/PostAdd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface AllianceModalInputProps {
  allianceCreate: (
    tradeAllianceRequest: TradeAllianceRequest,
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance, any>>;
  listCall: (
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance[], any>>;
  allianceTypeTitle: string;
  disabled: { success: boolean; message: string };
}

export default function AllianceModal({
  allianceCreate,
  allianceTypeTitle,
  listCall,
  disabled,
}: AllianceModalInputProps) {
  const nationsFetcher: Fetcher<Nation[]> = () => {
    return apiClient()
        .allNationsList()
        .then((res) => {
          return res.data;
        });
  };

  const { data: nations} = useSWR<Nation[], Error>(
      apiClient().allNationsList.name,
      nationsFetcher,
  );
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allianceType, setAllianceType] = useState("public");
  const [selectedNation, setSelectedNation] = useState<number|null>(null);
  const [selectedName, setSelectedName] = useState('');
  const default_alliance_text = `Geo-Strat AGREEMENT & ESTABLISHED ALLIANCE

1.This is a ${allianceTypeTitle} alliance agreement. This agreement is established between ${user.nation.name} and \u200B<Nation’s Name>\u200B.

2. This alliance intends to establish an agreement and understanding of the following: (Write out the “rules” or conditions of the agreement.)





3. If this agreement is broken the offending party agrees to the following terms:




4. Conditions which are acceptable to break the alliance agreement (if any):



`
  const [declaration, setDeclaration] = useState(default_alliance_text);
  const [disabledNations, setDisabledNations] = useState<number[]>([]);

  const tradeAlliancesFetcher: Fetcher<TradeAlliance[]> = () => {
    return listCall().then((res) => {
      return res.data;
    });
  };

  const { data: alliances, error } = useSWR<TradeAlliance[], Error>(
    listCall.name.replace("bound ", ""),
    tradeAlliancesFetcher,
  );

  useEffect(() => {
    const selected = nations?.find((n) => n.id === selectedNation)?.name;
    if (selected) {
      const regex = /\u200B(.*?)\u200B/;
      setDeclaration(declaration.replace(regex, `\u200B${selected}\u200B`));
    }
  }, [selectedNation]);

  useEffect(() => {
    if (alliances) {
      const existing = alliances.filter(
        (alliance) =>
          (alliance.offeringNationId === user?.nation.id ||
            alliance.receivingNationId === user?.nation.id) &&
          [
            TradeStatusStatusEnum.Created.toString(),
            TradeStatusStatusEnum.SenderAccepted.toString(),
            TradeStatusStatusEnum.ReceiverAccepted.toString(),
          ].indexOf(alliance.status!) > -1,
      );
      if (existing.length > 0) {
        const results: number[] = [];
        existing.forEach((alliance) => {
          results.push(alliance.offeringNationId);
          results.push(alliance.receivingNationId);
        });
        setDisabledNations(results);
      }
    }
  }, [alliances]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setAllianceType(event.target.value);
  };

  const handleNationChange = (event: SelectChangeEvent<number>) => {
    setSelectedNation(event.target.value as number);
  };

  const handleDeclarationChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDeclaration(event.target.value as string);
  };

  const submitAlliance = () => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    allianceCreate({
      offeringNationId: user?.nation.id || 0,
      receivingNationId: selectedNation!,
      declaration,
      isSecret: allianceType === "secret",
    })
      .then((r) => {
        enqueueSnackbar("Saved", { variant: "success" });
        myMutate(listCall.name);
        handleClose();
        setSelectedNation(null);
        setDeclaration(default_alliance_text);
      })
      .catch((e) => {
        enqueueSnackbar("Error: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  return (
    <>
      <IconButton
          onClick={handleOpen}
      >
        <PostAddIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogContent>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 0 }}
            component={"div"}
          >
            {user?.nation.name} would like to request a
            <FormControl fullWidth>
              <Select value={allianceType} onChange={handleChange}>
                <MenuItem value={"public"}>Public</MenuItem>
                <MenuItem value={"secret"}>Secret</MenuItem>
              </Select>
            </FormControl>
            {allianceTypeTitle} alliance with
            <NationDropdown
              selected={selectedNation}
              excluded={disabledNations}
              onChange={handleNationChange}
            />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 0 }}>
            Agreement
          </Typography>
          <TextareaAutosize
            css={css`
              width: 100%;
            `}
            aria-label="minimum height"
            minRows={6}
            onChange={handleDeclarationChange}
            value={declaration}
            placeholder=""
          />

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <GeoButton
                onClick={submitAlliance}
                title={disabled.message || (selectedNation == null ? "Must select a nation" : (declaration.trim().length < 100 ? "Declaration must be at least 100 characters" : ""))}
                disabled={!disabled.success || selectedNation == null || declaration.trim().length < 100  || saving}
            >
              Propose Alliance
            </GeoButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
