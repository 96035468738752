import * as React from "react";
import Constitution from "../../components/Constitution";
import {useParams} from "react-router-dom";
import useSWR, {Fetcher} from "swr";
import {Nations} from "../../my-api-client";
import {apiClient} from "../../services/apiClient.ts";
import LoadingScreen from "../../components/Loading/loadingScreen.tsx";

function ConstitutionPage() {
    const { id } = useParams();
    const nationFetcher: Fetcher<Nations> = () => {
        return apiClient()
            .allNationsRetrieve(parseInt(id || "0"))
            .then((res) => {
                return res.data;
            });
    };
    const { data: nation, isLoading ,isValidating, error } = useSWR<Nations, Error>(
        apiClient().allNationsRetrieve.name,
        nationFetcher,
    );

    if (!nation || isLoading || isValidating) {
        return <LoadingScreen fullScreen={true} />
    }

  return (
    <>
      <Constitution key={nation.id} nation={nation} />
    </>
  );
}

export default ConstitutionPage;
