/** @jsxImportSource @emotion/react */
import TradeAlliances from "./tradeAlliances";
import MilitaryAlliances from "./militaryAlliances";
import WarDeclarations from "./warDeclaration";
import WarningDeclarations from "./warningDeclaration";
import SubpoenaDeclarations from "./subpoenaDeclaration";
import { css } from "@emotion/react";
import JoyrideWrapper from "../Joyridewrapper";
import * as React from "react";
import {Box} from "@mui/material";

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useEffect} from "react";
import { useSearchParams } from "react-router-dom";

export default function Diplomacy() {
  const [queryParams, setQueryParams] = useSearchParams();

  useEffect(() => {
    const tab = queryParams.get("tab") || "trade_alliances";
    // @ts-ignore
    setValue(tab);
  }, [queryParams]);


  const steps = [
    {
      target: '.trade_alliances',
      content: 'Your nation can form trade alliances with other nations.  Trade alliances allow for the exchange of resources between nations.',
    },
    {
      target: '.military_alliances',
      content: 'Your nation can form military alliances with other nations.',
    },
    {
      target: '.subpoena_declarations',
      content: 'Your nation can issue subpoenas to other nations.',
    },
    {
      target: '.warning_declarations',
      content: 'Your nation can issue warnings to other nations.',
    },
    {
      target: '.war_declarations',
      content: 'Your nation can declare war on other nations.',
    }
  ];

  const [value, setValue] = React.useState('trade_alliances');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setQueryParams({ tab: newValue });
  };

  return (
    <>
      <JoyrideWrapper id={'diplomacy_ride'} steps={steps} />
      <Box sx={{  width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center'  }}>
          <TabList onChange={handleChange}           variant="scrollable"
                   scrollButtons="auto" allowScrollButtonsMobile>
            <Tab label="Trade Alliances" value="trade_alliances" className={"trade_alliances"} />
            <Tab label="Military Alliances" value="military_alliances" className={"military_alliances"} />
            <Tab label="Subpoenas" value="subpoenas" className={"subpoena_declarations"} />
            <Tab label="Warnings" value="warnings" className={"warning_declarations"} />
            <Tab label="Wars" value="wars" className={"war_declarations"} />
          </TabList>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '800px', maxWidth: '100%'}} >
          <TabPanel value="trade_alliances">
            <TradeAlliances />
          </TabPanel>
          <TabPanel value="military_alliances">
            <MilitaryAlliances />
          </TabPanel>
          <TabPanel value="subpoenas">
            <SubpoenaDeclarations />
          </TabPanel>
          <TabPanel value="warnings">
            <WarningDeclarations />
          </TabPanel>
          <TabPanel value="wars">
            <WarDeclarations />
          </TabPanel>
          </Box>
        </Box>

      </TabContext>
      </Box>

    </>
  );
}
