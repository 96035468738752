/** @jsxImportSource @emotion/react */
import NationContent from "../Nation/nationContent";
import { css } from "@emotion/react";
import useSWR, { Fetcher } from "swr";
import axios from "axios";
import { apiClient } from "../../services/apiClient";
import {
  Nations as NationsType,
  NationsStat
} from "../../my-api-client";
import Grid from "@mui/material/Grid";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import React from "react";

export default function Nations() {
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [resourcesOpen, setResourcesOpen] = React.useState(false);
  const [statsOpen, setStatsOpen] = React.useState(false);
  const [powerOpen, setPowerOpen] = React.useState(false);
  const nationsFetcher: Fetcher<NationsType[]> = () => {
    return apiClient()
      .allNationsList()
      .then((res) => {
        return res.data;
      });
  };

  const { data, error } = useSWR<NationsType[], Error>(
    apiClient().allNationsList.name,
    nationsFetcher,
  );

  const { data: stats, error: error2 } = useSWR<NationsStat, Error>(
    apiClient().allNationStatsRetrieve.name,
    function () {
      return apiClient()
        .allNationStatsRetrieve()
        .then((res) => {
          return res.data;
        });
    },
  );

  const statDict = {};
  for (const stat of stats?.nations || []) {
    // @ts-ignore
    statDict[stat.id] = stat;
  }

  if (error) return "Error loading nations.";

  if (!data) {
    return <LoadingScreen />;
  }

  return (
      <>
    <Grid container spacing={3} sx={{ p: 3, justifyContent: "center" }}>
      {data.filter(nation => nation.name != "World Bank").map((nation, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={nation.id}>
          <NationContent
              index={index}
            nation={nation}
            stats={
              // @ts-ignore
              statDict[nation.id] || {}
            }
            adminOpen={adminOpen}
            setAdminOpen={setAdminOpen}
            resourcesOpen={resourcesOpen}
            setResourcesOpen={setResourcesOpen}
            statsOpen={statsOpen}
            setStatsOpen={setStatsOpen}
            powerOpen={powerOpen}
            setPowerOpen={setPowerOpen}
          />
        </Grid>
      ))}
    </Grid>
      </>
  );
}
