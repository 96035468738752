// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { css } from "@emotion/react";
import { Item } from "../../my-api-client";

interface ItemInputProps extends TypographyProps {
  item: Item;
  costKey: string;
  title: string;
}

export default function Cost({ item, costKey, title, ...props }: ItemInputProps) {
  const resourceMap = {
    agCost: `${import.meta.env.VITE_CDN}/Ag.png`,
    lumberCost: `${import.meta.env.VITE_CDN}/Lumber.png`,
    oilCost: `${import.meta.env.VITE_CDN}/Oil.png`,
    mineralCost: `${import.meta.env.VITE_CDN}/Minerals.png`,
    dailyAgCost: `${import.meta.env.VITE_CDN}/Ag.png`,
    dailyLumberCost: `${import.meta.env.VITE_CDN}/Lumber.png`,
    dailyOilCost: `${import.meta.env.VITE_CDN}/Oil.png`,
    dailyMineralCost: `${import.meta.env.VITE_CDN}/Minerals.png`,
  }
  const keys1 = ["agCost", "lumberCost", "oilCost", "mineralCost"]
  const keys2 = ["dailyAgCost", "dailyLumberCost", "dailyOilCost", "dailyMineralCost"]
  const keys = costKey == 'dailyCost' ? keys2 : keys1;
  const hasCost = () => {
    const price = item.itemPrice
    if (costKey == 'dailyCost') {
      return price.dailyAgCost || price.dailyLumberCost || price.dailyOilCost || price.dailyMineralCost;
    } else {
      return price.agCost || price.lumberCost || price.oilCost || price.mineralCost;
    }
  }

  return (
    <>
      {hasCost() ? (
        <Typography
          variant="body2"
          color="text.secondary"
          component={"div"}
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          {...props}
        >
          <span>{title}:</span>
          <Box
            sx={{
              my: 1,
              display: "flex",
            }}
          >
            {keys.map(key => {
              return { key: key, price: item.itemPrice[key]}
            })
              .filter((x) => x.price > 0)
              .map((x) => {
                return (
                  <Typography
                    key={(item.id,x.key)}
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      css={css`
                        width: 20px;
                      `}
                      alt="image"
                      src={resourceMap[x.key]}
                    />
                    &nbsp;x {x.price}
                  </Typography>
                );
              })}
          </Box>
        </Typography>
      ) : ""}
    </>
  );
}
