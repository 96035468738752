import * as React from "react";
import { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "../link";
import { useUser } from "../../hooks/useUser";
import { Box, Button, ListItem, ListItemText } from "@mui/material";
import useLogout from "../../hooks/useLogout.tsx";
import { ContactSupport, Feed, Home, Info, Login } from '@mui/icons-material'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

interface NavItem {
  name: string;
  Icon: any;
  link: string;
  condition: boolean;
}

export default function LandingHeader(props: Props) {
  const user = useUser();
  const [navItems, setNavItems] = React.useState<NavItem[]>([]);
  const logout = useLogout();

  useEffect(() => {
    setNavItems([
      {
        name: "Home",
        Icon: Home,
        link: "/",
        condition: true
      },
      {
        name: "About",
        Icon: Info,
        link: "/about",
        condition: true
      },
      {
        name: "Contact",
        link: "/contact",
        Icon: ContactSupport,
        condition: true,
      },
      {
        name: "Go To App",
        link: "/app/news",
        Icon: Feed,
        condition: !!user,
      },
      {
        name: "Login",
        Icon: Login,
        link: "/login",
        condition: !user,
      },
      /*{
        name: "Sign Up",
        link: "/signup",
        condition: !user,
      }*/
    ]);
  }, [user]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <Box sx={{ py: 2, display: "flex", justifyContent: "center", backgroundColor: "primary.main", color: "#fff" }}>
          <Box sx={{ borderRadius: "50%", backgroundColor: "#fff", height: "32px", width: "32px" }}>
            <img alt="Logo" src="/logo.png" height="32" width="32"/>
          </Box>
        <Typography variant="h6" ml={1}>
          GeoStrat
        </Typography>
        </Box>
        <Divider />
        <List>
          {navItems
              .filter((item) => item.condition)
              .map(({ Icon, link, name }) => (
                  <ListItem key={name} disablePadding>
                    <Link href={link} key={link} pl={2} style={{ display: "flex", alignItems:"center", width: "100%", textDecoration: 'none' }}>
                      {!!Icon && <Icon />}
                      <ListItemButton sx={{ textAlign: "left" }}>
                        <ListItemText primary={name} />
                      </ListItemButton>
                    </Link>
                  </ListItem>
              ))}
          {user && (
              <ListItem disablePadding>
                <Link
                    style={{ width: "100%" }}
                    onClick={() => {
                      logout();
                    }}
                >
                  <ListItemButton sx={{ textAlign: "left" }}>
                    <ListItemText primary={"Logout"} />
                  </ListItemButton>
                </Link>
              </ListItem>
          )}
        </List>
      </Box>
  );

  const container =
      window !== undefined ? () => window().document.body : undefined;

  return (
      <Box sx={{ display: "flex", height: "64px" }}>
        <AppBar component="nav">
          <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: "flex", justifyItems: "center", alignItems: "center" }}>
              <Box sx={{ borderRadius: "50%", backgroundColor: "#fff", height: "32px", width: "32px" }}>
                <img alt="Logo" src="logo.png" height="32" width="32"/>
              </Box>
              <Typography
                  ml={1}
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1 }}
              >
                GeoStrat
              </Typography>
            </Box>

            <Box sx={{ display: { xs: "none", sm: "block" }, height: "32px" }}>
              {navItems
                  .filter((item) => item.condition)
                  .map((item) => (
                      <Link href={item.link} key={item.link}>
                        <Button key={item.name} sx={{ color: "#fff" }}>
                          {item.name}
                        </Button>
                      </Link>
                  ))}
              {user && (
                  <Link
                      onClick={() => {
                        logout();
                      }}
                  >
                    <Button sx={{ color: "#fff" }}>Log Out</Button>
                  </Link>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
  );
}
