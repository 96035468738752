/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import {
  Typography,
} from "@mui/material";
import TradesTable from "./TradesTable";
import useSWR, { Fetcher } from "swr";
import {
  Nations,
  Resource,
  Trade as TradeType
} from "../../my-api-client";
import { apiClient } from "../../services/apiClient";
import { useUser } from "../../hooks/useUser";
import { css } from "@emotion/react";
import Container from "@mui/material/Container";
import GeoTooltip from "../GeoTooltip";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import JoyrideWrapper from "../Joyridewrapper";
import TradeForm from "./TradeForm.tsx";
import TradeDialog from "./TradeDialog.tsx";

export default function Trade() {
  const user = useUser();
  const [myTeam, setMyTeam] = useState<Nations>();
  const [selected, setSelected] = useState<Nations>();

  const [teamDict, setTeamDict] = useState<{ [fieldName: string]: Nations }>(
    {},
  );
  const [outgoingTrades, setOutgoingTrades] = useState<TradeType[]>();
  const [incomingTrades, setIncomingTrades] = useState<TradeType[]>();

  const nationsFetcher: Fetcher<Nations[]> = () => {
    return apiClient()
      .allNationsList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: nations, error } = useSWR<Nations[], Error>(
    apiClient().allNationsList.name,
    nationsFetcher,
  );

  const tradesFetcher: Fetcher<TradeType[]> = () => {
    return apiClient()
      .allTradesList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: trades, isLoading } = useSWR<TradeType[], Error>(
    apiClient().allTradesList.name,
    tradesFetcher,
  );

  useEffect(() => {
    if (myTeam) {
      setOutgoingTrades(
        trades?.filter((x) => {
          return x.offeringNationId === myTeam.id;
        }),
      );
      setIncomingTrades(
        trades?.filter((x) => {
          return x.receivingNationId === myTeam.id;
        }),
      );
    }
  }, [trades, myTeam]);

  useEffect(() => {
    if (nations && user && user.nation) {
      const dict: { [key: number]: Nations } = {};

      let autoSelected: Nations | undefined;
      for (let i = 0; i < nations.length; i++) {
        const nation = nations[i];
        dict[nation.id] = nation;
        if (nation.id != user.nation.id && (!autoSelected || nation.hasTradePort)) {
          autoSelected = nation;
        }
      }

      setTeamDict(dict);
      setMyTeam(dict[user.nation.id]);
      if (!selected?.id && autoSelected) {
        setSelected(autoSelected);
      } else if (selected?.id) {
        setSelected(dict[selected?.id])
      } else {
        console.warn("Selected is not set");
      }
    }
  }, [nations, user]);

  if (!myTeam || !teamDict[myTeam.id]) {
    return <LoadingScreen />;
  }

  const steps = [
    {
      target: '#new_trade_button',
      content: 'Trade resources with other nations',
    }
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <JoyrideWrapper id={'trade_ride'} steps={steps} />
      <Container
        sx={{
          maxWidth: "100%",
        }}
      >
        {
          <>
            <Typography gutterBottom variant="h6" component="div" sx={{display: 'inline'}}>
              Outgoing Trades
            </Typography>
            <TradeDialog />
            <TradesTable trades={outgoingTrades} incoming={false} isLoading={isLoading} />
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 5 }}
            >
              Incoming Trades
            </Typography>
            <TradesTable trades={incomingTrades} incoming={true} isLoading={isLoading} />
          </>
        }
      </Container>
    </div>
  );
}
