import { createTheme, alpha, getContrastRatio } from '@mui/material/styles';


let theme = createTheme({
  palette: {
    primary: {
      main: "#2f2f2f", // Blue
    },
    background: {
      default: "#f5f5f5", // Light grey background
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Normal casing on buttons
        },
      },
    },
  },
});

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    bland: theme.palette.augmentColor({
      color: {
        main: '#2f2f2f',
      },
      name: 'bland',
    })
  },
});

export default theme;
