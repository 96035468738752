import React from 'react';
import {Container, Typography, Box } from '@mui/material';
import WaitlistForm from "./waitlistForm.tsx";

const WelcomePage = () => {

    return (
        <Container maxWidth="md">
            <Box mt={8} textAlign="center">
                <Typography variant="h3" gutterBottom>
                    Welcome to GeoStrat!
                </Typography>
                <Typography variant="h6" paragraph>
                    We're still working on some things. Join our waitlist for early access and to be the first to know when we launch.
                </Typography>
                {/*<Typography variant="body1">*/}
                {/*    GeoStrat is a educational game where gameplay can be used to help reinforce concepts learned in the classroom.*/}
                {/*    In this game students will be assigned to 1 of 4 different nations.  Each student will have their own role to play on their team.*/}
                {/*    Students will work to establish their nation.  They will start with creating a flag for their nation and creating a constitution.*/}
                {/*    They can trade with other nations to gain needed resources.  They can build buildings to help increase their resources or*/}
                {/*    to improve their nation.  They can form alliances with other nations or go to war with each other.*/}
                {/*</Typography>*/}
                <WaitlistForm/>
            </Box>
        </Container>
    );
};

export default WelcomePage;
