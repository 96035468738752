import { Item, Nation, Trade, UserClass, Purchase } from "../my-api-client";

export const permissionList = [
  { id: "CAN_UPLOAD_FLAG", name: "Can Update Nations Flag" },
  { id: "CAN_INITIATE_TRADES", name: "Can Initiate Trades" },
  { id: "CAN_EDIT_CONSTITUTION", name: "Can Edit Constitution" },
];

let ADMIN_ON = true;

const adminOn = localStorage.getItem("adminOn");

if (adminOn === "false") {
  // Changed from `=== true` to `!== null`
  ADMIN_ON = false;
}
// You're an admin you can do anything
const ADMIN_MESSAGE = "";
const ADMIN_RETURN = true;

export interface PermissionResult {
  success: boolean;
  message: string;
}

const withAdminCheck = (fn: any) => {
  return function (user: UserClass, ...args: any) {
    if (!user) {
      return { success: false, message: "Invalid User" };
    }
    if (ADMIN_ON && user.isAdmin) {
      return { success: ADMIN_RETURN, message: ADMIN_MESSAGE };
    }
    const result = fn(user, ...args);

    if (result) {
      return { success: false, message: result };
    }

    return { success: true, message: "" };
  };
};

export const permissionService = {
  setAdminOn: (on: boolean) => {
    ADMIN_ON = on;
    localStorage.setItem("adminOn", on.toString());
    location.reload();
  },
  getAdminOn: () => {
    return ADMIN_ON;
  },
  isAdmin: withAdminCheck((user: UserClass) => {
    return "Must be an admin";
  }),
  canPurchaseItem: withAdminCheck((user: UserClass, item: Item) => {
    if (item.name === "Informal Settlement") {
        return "Cannot build informal settlements";
    }
    if (item.name === "Fishing Boat") {
      if (!user.nation.hasTradePort) {
        return "Must build trade port";
      }
    }
    if (item.name === "City") {
      if (!user.nation.hasVillage) {
        return "Must build village";
      }
    }
    if (item.name === "Megalopolis" || item.name === "University" || item.name === "Military Base") {
      if (!user.nation.hasCity) {
        return "Must build city";
      }
    }
  }),
  canUploadFlag: withAdminCheck((user: UserClass, nation: Nation) => {
    if (!permissionService.hasPermission(user, "CAN_UPLOAD_FLAG")) {
      return "The Comptroller must update the flag";
    }
    if (user.nation.id !== nation.id) {
      return "You do not have permissions to update other nations flags";
    }
  }),
  canEditConstitution: withAdminCheck((user: UserClass, nationId: number) => {
    if (!permissionService.hasPermission(user, "CAN_EDIT_CONSTITUTION")) {
      return "The Comptroller must edit the constitution";
    }
    if (user.nation.id !== nationId) {
      return "You do not have permissions to edit other nations constitutions";
    }
  }),
  canInitiateTrades: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_INITIATE_TRADES")) {
      return "The CFO or Comptroller must initiate trades";
    }
  }),
  canApproveOutgoingTrades: withAdminCheck((user: UserClass, trade: Trade) => {
    if (!permissionService.hasPermission(user, "CAN_APPROVE_OUTGOING_TRADES")) {
      return "The Comptroller or CFO must approve outgoing trades";
    }
    if (trade.createdBy === user.id) {
      return "The CFO or Comptroller must approve outgoing trades. You cannot approve your own trade.";
    }
    if (trade.offeringNation.id !== user.nation.id) {
      return "You are not allowed to approve other nations trades";
    }
  }),
  canCancelOutgoingTrades: withAdminCheck((user: UserClass, trade: Trade) => {
    if (!permissionService.hasPermission(user, "CAN_APPROVE_OUTGOING_TRADES")) {
      return "The CFO or Comptroller must cancel outgoing trades";
    }
    if (trade.offeringNation.id != user.nation.id) {
      return "You are not allowed to cancel trades from other nations";
    }
  }),
  canAcceptIncomingTrades: withAdminCheck((user: UserClass, trade: Trade) => {
    if (!permissionService.hasPermission(user, "CAN_ACCEPT_INCOMING_TRADES")) {
      return "The CFO or Comptroller must accept incoming trades";
    }
    if (trade.createdBy === user.id) {
      return "You are not allowed to accept or reject your own trade";
    }
    return "";
  }),
  canPublishNews: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_PUBLISH_NEWS")) {
      return "The Secretary of State has permission to publish news";
    }
  }),
  canEditNews: withAdminCheck((user: UserClass, nationId: number) => {
    if (!permissionService.hasPermission(user, "CAN_PUBLISH_NEWS")) {
      return "The Secretary of State has permission to edit articles";
    }
    if (user.nation.id !== nationId) {
      return "You do not have permissions to edit this article";
    }
  }),
  canComment: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_COMMENT")) {
      return "You do not have permissions to comment";
    }
  }),
  canPurchaseInfrastructure: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_PURCHASE_INFRASTRUCTURE")) {
      return "The CFO or Comptroller has permissions to purchase infrastructure";
    }
  }),
  canApproveInfrastructure: withAdminCheck((user: UserClass, id: number) => {
    if (!permissionService.hasPermission(user, "CAN_APPROVE_INFRASTRUCTURE")) {
      return "The CFO or Comptroller can approve infrastructure";
    }
    if (user && user.id == id) {
      return "Not allowed to approve your own infrasturcture purchase. The CFO or Comptroller can approve infrastructure.";
    }
  }),
  canPurchaseMilitary: withAdminCheck((user: UserClass) => {
    if (!user.nation.hasMilitaryBase) {
        return "Must build and place military base";
    }
    if (!permissionService.hasPermission(user, "CAN_PURCHASE_MILITARY")) {
      return "The Defense Minister or Prime Minister has permissions to purchase military";
    }
  }),
  canApproveMilitary: withAdminCheck((user: UserClass, id: number) => {
    if (!permissionService.hasPermission(user, "CAN_APPROVE_MILITARY")) {
      return "The Defense Minister or Prime Minister has permissions to approve military";
    }
    if (user && user.id == id) {
      return "Not allowed to approve your own military purchase. The prime minister or defense minister has permissions to approve military.";
    }
  }),
  canPlacePurchase: withAdminCheck((user: UserClass, purchase: Purchase) => {
    if (purchase.item.isMilitary) {
      if (!permissionService.hasPermission(user, "CAN_PLACE_MILITARY")) {
        return "The Defense Minister or Prime Minister must place military";
      }
    } else {
      if (!permissionService.hasPermission(user, "CAN_PLACE_INFRASTRUCTURE")) {
        return "The CFO or Comptroller must place infrastructure";
      }
    }

  }),
  canInitiateTradeAlliance: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_INITIATE_TRADE_ALLIANCE")) {
      return "The defense minister may not initiate trade alliances";
    }
  }),
  canApproveOutgoingTradeAlliance: withAdminCheck(
    (user: UserClass, id: number) => {
      if (
        !permissionService.hasPermission(
          user,
          "CAN_APPROVE_OUTGOING_TRADE_ALLIANCE",
        )
      ) {
        return "The defense minister may not approve outgoing trade alliances";
      }
      if (user && user.id == id) {
        return "Not allowed to approve your own alliance";
      }
    },
  ),
  canCancelOutgoingTradeAlliance: withAdminCheck(
    (user: UserClass, id: number) => {
      if (
        !permissionService.hasPermission(
          user,
          "CAN_APPROVE_OUTGOING_TRADE_ALLIANCE",
        )
      ) {
        return "The Defense Minister may not cancel outgoing trade alliances";
      }
    },
  ),
  canAcceptIncomingTradeAlliance: withAdminCheck(
    (user: UserClass, id: number) => {
      if (
        !permissionService.hasPermission(
          user,
          "CAN_ACCEPT_INCOMING_TRADE_ALLIANCE",
        )
      ) {
        return "The defense minister may not approve incoming trade alliances";
      }
      if (user && user.id == id) {
        return "Not allowed to approve your own alliance";
      }
    },
  ),
  canCancelIncomingTradeAlliance: withAdminCheck(
    (user: UserClass, id: number) => {
      if (
        !permissionService.hasPermission(
          user,
          "CAN_ACCEPT_INCOMING_TRADE_ALLIANCE",
        )
      ) {
        return "The defense minister may not cancel incoming trade alliances";
      }
    },
  ),
  canInitiateMilitaryAlliance: withAdminCheck((user: UserClass) => {
    if (
      !permissionService.hasPermission(user, "CAN_INITIATE_MILITARY_ALLIANCE")
    ) {
      return "The CFO may not initiate military alliances";
    }
  }),
  canApproveOutgoingMilitaryAlliance: withAdminCheck(
    (user: UserClass, id: number) => {
      if (
        !permissionService.hasPermission(
          user,
          "CAN_APPROVE_OUTGOING_MILITARY_ALLIANCE",
        )
      ) {
        return "The CFO may not approve outgoing military alliances";
      }
      if (user && user.id == id) {
        return "Not allowed to approve your own alliance";
      }
    },
  ),
  canCancelOutgoingMilitaryAlliance: withAdminCheck(
    (user: UserClass, id: number) => {
      if (
        !permissionService.hasPermission(
          user,
          "CAN_APPROVE_OUTGOING_MILITARY_ALLIANCE",
        )
      ) {
        return "The CFO may not approve outgoing military alliances";
      }
    },
  ),
  canCancelMilitaryAlliance: withAdminCheck((user: UserClass, id: number) => {
    if (
      !permissionService.hasPermission(
        user,
        "CAN_APPROVE_OUTGOING_MILITARY_ALLIANCE",
      ) &&
      !permissionService.hasPermission(
        user,
        "CAN_ACCEPT_INCOMING_MILITARY_ALLIANCE",
      )
    ) {
      return "The CFO may not cancel military alliances";
    }
  }),
  canAcceptIncomingMilitaryAlliance: withAdminCheck(
    (user: UserClass, id: number) => {
      if (
        !permissionService.hasPermission(
          user,
          "CAN_ACCEPT_INCOMING_MILITARY_ALLIANCE",
        )
      ) {
        return "The CFO may not accept incoming military alliances";
      }
      if (user && user.id == id) {
        return "Not allowed to accept your own alliance";
      }
    },
  ),
  canCancelIncomingMilitaryAlliance: withAdminCheck(
    (user: UserClass, id: number) => {
      if (
        !permissionService.hasPermission(
          user,
          "CAN_ACCEPT_INCOMING_MILITARY_ALLIANCE",
        )
      ) {
        return "The CFO may not cancel incoming military alliances";
      }
    },
  ),
  canInitiateWarning: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_INITIATE_WARNING")) {
      return "The Secretary of State or Defense Minister must initiate warnings";
    }
  }),
  canApproveOutgoingWarning: withAdminCheck((user: UserClass, id: number) => {
    if (!permissionService.hasPermission(user, "CAN_APPROVE_WARNING")) {
      return "The Secretary of State, Defense Minister, or Prime minister can approve warnings";
    }
    if (user && user.id == id) {
      return "Not allowed to approve your own warning. The Secretary of State, Defense Minister, or Prime minister can approve warnings. ";
    }
  }),
  canInitiateWar: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_INITIATE_WAR")) {
      return "War must be initialized by the Defense Minister";
    }
  }),
  canApproveOutgoingWar: withAdminCheck((user: UserClass, id: number) => {
    if (!permissionService.hasPermission(user, "CAN_APPROVE_WAR")) {
      return "Wars must be approved by the prime minister or secretary of state";
    }
    if (user && user.id == id) {
      return "Not allowed to approve your own war. Wars must be approved by the prime minister or secretary of state. ";
    }
  }),
  canInitiateSubpoena: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_INITIATE_SUBPOENA")) {
      return "The CFO cannot initiate subpoenas";
    }
  }),
  canApproveOutgoingSubpoena: withAdminCheck((user: UserClass, id: number) => {
    if (!permissionService.hasPermission(user, "CAN_APPROVE_SUBPOENA")) {
      return "The CFO cannot approve subpoenas";
    }
    if (user && user.id == id) {
      return "Not allowed to approve your own subpoenas";
    }
  }),
  canEditProfile: withAdminCheck((user: UserClass, id: number) => {
    if (user && user.id != id) {
      return "You do not have permissions to edit this profile";
    }
  }),
  canEditPurchaseName: withAdminCheck((user: UserClass) => {
    if (!permissionService.hasPermission(user, "CAN_EDIT_PURCHASE_NAME")) {
      return "The Comptroller or SOS must edit name";
    }
  }),
  hasPermission:(user: UserClass, permission: string) => {
    return user.permissions && user.permissions[permission];
  },
};
