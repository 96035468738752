/** @jsxImportSource @emotion/react */
import { apiClient } from "../../services/apiClient";
import Alliances from "./alliances";
import { Box, Typography } from "@mui/material";

import DeclarationModal from "./declarationModal";
import { useUser } from "../../hooks/useUser";
import { permissionService } from "../../services/permissionService";
import { getButtonSend } from "./utils";
import { TradeAlliance } from "../../my-api-client";
import React from "react";

export default function WarningDeclarations() {
  const user = useUser();
  return (
    <Box  sx={{
        textAlign: 'center'
    }}>
      <DeclarationModal
        listCall={apiClient().allWarningsList.bind(apiClient())}
        allianceCreate={apiClient().allWarningsCreate.bind(apiClient())}
        allianceTypeTitle={"Send Warning"}
        allianceTypeText={"send a warning to"}
        disabled={permissionService.canInitiateWarning(user)}
      />
      <Alliances
        listCall={apiClient().allWarningsList.bind(apiClient())}
        buttonMapSend={getButtonSend(user, "Warning", "Warning")}
        buttonMapReceive={(alliance: TradeAlliance) => {
          return {};
        }}
        customStatusMap={
            {
                SENDER_ACCEPTED: "Delivered"
            }
        }
      ></Alliances>
    </Box>
  );
}
