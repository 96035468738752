/** @jsxImportSource @emotion/react */
import { apiClient } from "../../services/apiClient";
import Alliances from "./alliances";
import { Box, Typography } from "@mui/material";

import AllianceModal from "./allianceModal";
import { useUser } from "../../hooks/useUser";
import { permissionService } from "../../services/permissionService";
import { getButtonReceive, getButtonSend } from "./utils";
import React from "react";

export default function MilitaryAlliances() {
  const user = useUser();
  return (
    <Box  sx={{
        textAlign: 'center'
    }}>
      <AllianceModal
        listCall={apiClient().allMilitaryAlliancesList.bind(apiClient())}
        allianceCreate={apiClient().allMilitaryAlliancesCreate.bind(
          apiClient(),
        )}
        allianceTypeTitle={"Military"}
        disabled={permissionService.canInitiateMilitaryAlliance(user)}
      />
      <Alliances
        listCall={apiClient().allMilitaryAlliancesList.bind(apiClient())}
        buttonMapSend={getButtonSend(
          user,
          "Military Alliance",
          "MilitaryAlliance",
        )}
        buttonMapReceive={getButtonReceive(
          user,
          "Military Alliance",
          "MilitaryAlliance",
        )}
      ></Alliances>
    </Box>
  );
}
