import Joyride, {STATUS} from "react-joyride";
import * as React from "react";
import * as ReactJoyride from "react-joyride";
import {Tutorial} from "../../my-api-client";
import {apiClient} from "../../services/apiClient.ts";
import useSWR from "swr";
import {myMutate} from "../../hooks/useUser.ts";

interface JoyrideWrapperProps extends ReactJoyride.Props {
    id: string
}

export default function JoyrideWrapper({ id, ...props }: JoyrideWrapperProps) {
    const [run, setRun] = React.useState(false);

    const { data, error } = useSWR<Tutorial[], Error>(
        apiClient().allTutorialList.name,
        function () {
            return apiClient()
                .allTutorialList()
                .then((res) => {
                    return res.data;
                });
        },
    );
    let steps = props.steps;

    React.useEffect(() => {
        if (data && data.filter((tutorial) => (id) == tutorial.section).length == 0) {
            setRun(true);
        }
    }, [data]);

    React.useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [error]);

  return <Joyride debug={false} scrollOffset={200}
                  run={run}
                  continuous={true}
                  locale={{
                      last: 'Finish'
                  }}
      //                 styles={{
  //   options: {
  //     zIndex: 1200,
  //   },
  // }}
  {...props}
  callback={({ status }) => {
      if (([STATUS.FINISHED.toString(), STATUS.SKIPPED.toString()]).includes(status)) {
          apiClient().allTutorialCreate({section: id}).then(() => {
              myMutate(apiClient().allTutorialList.name);
          });
      }
  }} steps={steps}
  />
}