import React, {useState} from "react";
import {
    Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, MenuItem,
    Select, TextField, SelectChangeEvent,
} from "@mui/material";
import GeoButton from "../GeoButton";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import {adminApiClient, apiClient} from "../../services/apiClient.ts";
import {useNations} from "../../hooks/useNations.ts";
import {Stat} from "./nationStatsDisplay.tsx";
import {mutate} from "swr";


interface PopulationChangeProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    population: Stat,
    nationId: number,
    housing: Stat
}

// Main Component to Display Nation Stats as List
const PopulationChange: React.FC<PopulationChangeProps> = ({ open, setOpen, population, nationId, housing }) => {
    const [nation, setNation] = useState(nationId.toString());
    const [populationChange, setPopulation] = useState('0');
    const [description, setDescription] = useState('');
    const [saving, setSaving] = useState(false);
    const {data: nations, error} = useNations();

    const save = async () => {
        const snack = enqueueSnackbar(
            <>
                Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <CircularProgress />
            </>,
            {
                variant: "info",
                persist: true,
            },
        );
        setSaving(true);
        adminApiClient().drfadminPopulationChangeEventCreate({nation: parseInt(nation), populationChange: parseInt(populationChange), event: description})
            .then((res) => {
                mutate(apiClient().allNationStatsRetrieve.name);
                mutate(apiClient().allPurchasesList.name);
                population.value = population.value + parseInt(populationChange);
                enqueueSnackbar("Population event saved", {
                    variant: "success",
                });
        }).catch((error) => {
            console.error(error);
            enqueueSnackbar("Error saving population event: " + error?.response?.data?.detail, {
                variant: "error",
            });
        })
            .finally(() => {
            setSaving(false);
            closeSnackbar(snack);
            setOpen(false);
            setDescription("")
            setPopulation("0")
        });
    }

    return (
        <Dialog
            open={open}
            onClose={() => {setOpen(false)}}
            maxWidth={"sm"}
        >
            <DialogTitle>New Population Event</DialogTitle>
            <DialogContent
                style={{
                    height: "90vh",
                }}
            >
                <DialogContentText>
                    Add or remove population from a nation.  The reason for the event will be published to the daily news.
                    When removing population you will also want to add a population event for the nation these people are moving to.
                </DialogContentText>

                <Box sx={{width: "300px", padding: "10px"}}>
                    <Select
                        sx={{width: "100%"}}
                        disabled
                    label="Nation"
                    value={nation}

                    onChange={(event: SelectChangeEvent) => setNation(event.target.value)}
                >
                    {nations!.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
                </Box>
                <Box sx={{width: "300px", padding: "10px"}}>
                <TextField
                    sx={{width: "100%"}}
                    label="Population Increase or Decrease"
                    type="number"
                    value={populationChange}
                    InputProps={{
                        inputProps: {
                            max: 100, min: 10
                        }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPopulation(event.target.value)}

                />
                    { parseInt(populationChange || "0") > 15000 &&
                    <Box sx={{width: "300px", padding: "10px", color: 'red'}}>
                        Warning: Maximum population increase is limited to 15,000 at a time
                    </Box>
                    }
            </Box>
                <Box sx={{width: "300px", padding: "10px"}}>
                    <TextField
                        sx={{width: "100%"}}
                        disabled={true}
                        label="New Population"
                        type="number"
                        value={population.value + parseInt(populationChange || "0")}
                    />
                </Box>
                <Box sx={{width: "300px", padding: "10px"}}>
                    {population.value + parseInt(populationChange || "0") > housing.value ? "Note: Population increase will cause an informal settlement to appear." +
                        "  An informal settlement will house the additional people but will incur the nation a daily cost until they build more housing" : ""}
                    {parseInt(populationChange) < 0 && parseInt(population.value) + parseInt(populationChange || "0") < (parseInt(housing.value) - 15000) ? "Note: Population decrease may cause an informal settlement to disappear" : ""}
                </Box>
                <Box sx={{width: "300px", padding: "10px"}}>
                <TextField
                    sx={{width: "100%"}}
                    label="Reason for population change"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setDescription(event.target.value)}

                />
        </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => {setOpen(false)}} disabled={saving}>
                    Cancel
                </Button>
                <GeoButton variant="outlined" onClick={save} disabled={saving || !description || !populationChange || !nation || parseInt(populationChange) > 15000}>
                    Save
                </GeoButton>
            </DialogActions>
        </Dialog>
    );
};

export default PopulationChange;
