import React, { useState } from "react";
import {CircularProgress, TextField} from "@mui/material";
import { Button, IconButton } from "@mui/material";
import PostAddIcon from '@mui/icons-material/PostAdd';

import { useSWRConfig } from "swr";
import { permissionService } from "../../../services/permissionService.ts";
import { myMutate, useUser } from "../../../hooks/useUser.ts";
import { Article } from "../../../my-api-client";
import { apiClient } from "../../../services/apiClient.ts";
import Box from "@mui/material/Box";
import {closeSnackbar, enqueueSnackbar} from "notistack";

export default function AddComment({ article }: { article: Article }) {
  const [saving, setSaving] = useState(false);
  const [comment, setComment] = useState("");
  const { mutate } = useSWRConfig();
  const user = useUser();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

      const snack = enqueueSnackbar(
          <>
              Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <CircularProgress />
          </>,
          {
              variant: "info",
              persist: true,
          },
      );
    setSaving(true);
    apiClient()
      .allCommentsCreate({ content: comment, article: article.id })
      .then((res) => {
          enqueueSnackbar("Comment Published", { variant: "success" });
        myMutate(apiClient().allArticlesList.name);
        myMutate(apiClient().allArticlesRetrieve.name);
        return res.data;
      }).catch((e) => {
            enqueueSnackbar("Error creating comment: " + e.toString(), {
                variant: "error",
            });
        })
        .finally(() => {
            setSaving(false);
            closeSnackbar(snack);
        });

    setComment("");
  };

  if (!permissionService.canComment(user).success) {
    return;
  }

  return (
    <>
      <TextField
        label="Add a Comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        multiline
        rows={4}
        fullWidth
        margin="normal"
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <IconButton
            color={"bland"}
          onClick={handleSubmit}
          sx={{ mt: 1 }}
          disabled={!comment || saving}
          type="submit"
        >
            <PostAddIcon />
        </IconButton>

      </Box>
    </>
  );
}
