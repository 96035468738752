import { Typography } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "../link";

export default function Footer(props: any) {
  return (
    <>
      {
        <Box component="footer" sx={{ py: 6 }}>
          <Container maxWidth="lg">
            <Typography variant="body2" color="textSecondary" align="center">
              © {new Date().getFullYear()} GeoStrat
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              <Link href="/privacy-policy" color="inherit">
                Privacy Policy
              </Link>
              {" | "}
              <Link href="/terms-of-service" color="inherit">
                Terms of Service
              </Link>
            </Typography>
          </Container>
        </Box>
      }
    </>
  );
}
