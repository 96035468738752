/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, {Fragment, useEffect, useRef, useState} from "react";
import Cookies from "js-cookie";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import {Box, CardMedia, CircularProgress} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { UploadAvatar } from "./upload.tsx";
// @ts-ignore
import ColorThief from 'colorthief/dist/color-thief.mjs'
import GeoWrapperTooltip from "../GeoWrapperTooltip/index.tsx";




export default function ImageBox({
    id,
  image,
  upload,
  field,
  canEdit,
  circle, setNationColor
}: {
  id: string,
  image: string;
  upload: any;
  field: string;
  canEdit: boolean;
  circle: boolean;
  setNationColor?: (color: number[]) => void;
}) {
  const imgRef = React.useRef<HTMLImageElement>(null);

  const [saving, setSaving] = useState(false);
  const selectedFile = useRef();
  const [selectedImage, setSelectedImage] = useState(image);

  // if (selectedImage && selectedImage.startsWith(import.meta.env.VITE_CDN)) {
  //   setSelectedImage(selectedImage.replace(import.meta.env.VITE_CDN, ""));
  // }
  const [editMode, setEditMode] = useState(false);
  const [newAvatarUrl, setNewAvatarUrl] = useState("");

  const onFileChange = async (event: any) => {
    if (!event.target.files[0]) {
      return;
    }
    if (event.target.files[0].size > 10485760) {
      enqueueSnackbar("File must be 10MB or less", {
        variant: "error",
      });
      return;
    }
    selectedFile.current = event.target.files[0];

    if (event.target.files) {
      setEditMode(true);
      setNewAvatarUrl(URL.createObjectURL(event.target.files[0]));
    }
    //await onFileUpload();
  };

  const onFileUpload = async (file: File) => {
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append(field, file, file.name);
    // Didn't work
    /*const request = {};
    request[field] = selectedFile.current;
    apiClient
      .allUsersPartialUpdate(id, request)
      .then((res) => {
        import.meta.env.
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(e, { variant: "error" });
      });
*/

    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);

    fetch(`${import.meta.env.VITE_NODE}/all/${upload}`, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      credentials: "include",
      method: "PATCH",
      body: formData,
    })
      .then((r) => {
        closeSnackbar(snack);
        return r.json().then((result) => {
          if (r.status.toString().startsWith("2")) {
            setSelectedImage(result["imageUrl"]);
            enqueueSnackbar("Image Uploaded", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(result.toString(), {
              variant: "error",
            });
          }

          return result;
        });
      })
      .catch((e) => {
        closeSnackbar(snack);
        enqueueSnackbar("Error uploading image: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setEditMode(false);
        setSaving(false);
      });
  };
  const loadData = () => {
    if (imgRef.current && imgRef.current.offsetWidth > 0) {
      const img = imgRef.current;
      const colorThief = new ColorThief();
      try {
        const result: number[] = colorThief.getColor(img);
        setNationColor && setNationColor(result);
      } catch (e) {
        // console.error("Error getting color");
        // console.error(imgRef.current);
        // console.error(e);
      }
    }
  }

  useEffect(() =>{
    loadData();
  }, [imgRef])

  const getImage = () => {
    if (selectedImage) {
      if (circle) {
        return <Avatar src={selectedImage} sx={{ width: 60, height: 60 }} />;
      } else {
        return <img src={selectedImage} alt={``} width={"100%"}
                    css={css`
                      height: fit-content
      `}
                    onLoad={loadData}
                    crossOrigin = 'anonymous'
                    ref={imgRef}
        />;
      }
    } else {
      if (circle) {
        return (
          <Avatar component="div">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-file-earmark-arrow-up"
              viewBox="0 0 16 16"
            >
              <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z" />
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
            </svg>
          </Avatar>
        );
      } else {
        return (
          <CardMedia component="div" sx={{display: "flex", justifyContent: "center", pt: 3}}>
            <GeoWrapperTooltip title={field == 'flag' ? "Upload a 2:3 Flag for your nation" : ''} placement="top">
              <svg
                width={"100"}
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-file-earmark-arrow-up"
                viewBox="0 0 16 16"
              >
                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z" />
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              </svg>
            </GeoWrapperTooltip>
          </CardMedia>
        );
      }
    }
  };

  return (
    <div id={id}>
      {editMode && (
        <UploadAvatar
          cancelEdit={() => setEditMode(false)}
          userId={""}
          avatarUrl={newAvatarUrl}
          onFileUpload={onFileUpload}
          width={circle ? 300 : 300}
          height={circle ? 300 : 200}
          initialAspectRatio={circle ? 1 : 3 / 2}
          saving={saving}
        />
      )}

      {canEdit ? (
        <label
          css={css`
            height: 100%;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
          `}
        >
          {getImage()}

          <input
            type="file"
            style={{ display: "none" }}
            onChange={onFileChange}
            accept="image/png, image/jpeg"
          />
        </label>
      ) : (
        getImage()
      )}
    </div>
  );
}
