/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ImageBox from "../ImageBox";
import { permissionService } from "../../services/permissionService";
import { useParams } from "react-router-dom";
import { useProfileUser } from "../../hooks/useProfileUser";
import { Card, CardContent, ListItem, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Link from "../link";
import React from "react";
import { useUser } from "../../hooks/useUser.ts";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import JoyrideWrapper from "../Joyridewrapper";

export default function Profile() {
  const currentUser = useUser();
  const { id } = useParams();
  const user = useProfileUser(parseInt(id || ""));

  if (!user) {
    return <LoadingScreen />;
  }

    const steps = [
        {
            target: '#profile_image_box',
            content: 'Upload an image here to display as your profile picture.',
        }
    ];

  return (
    <>
        <JoyrideWrapper id={'user_profile_ride'} steps={steps} />

        <Card
        sx={{ maxWidth: 300, margin: "auto", width: "100%" }}
        variant="outlined"
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <ImageBox
              id={"profile_image_box"}
            image={user.imageUrl}
            field={"profile_picture"}
            upload={`users/${user.id}/`}
            canEdit={
              permissionService.canEditProfile(currentUser, user.id).success
            }
            circle={true}
          />
          <Typography variant="h6">{user.fullName}</Typography>
          <Link href={`/app/nations/${user.nation.id}`}>
            <Typography color="textSecondary">{user.nation.name}</Typography>
          </Link>
          <List sx={{ padding: "0" }}>
            {user.roles.map((role) => (
              <ListItem key={role.id} sx={{ padding: "0" }}>
                <ListItemText primary={role.name} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  );
}
