/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useUser } from "../../hooks/useUser";
import ImageBox from "../ImageBox";
import React from "react";
import Constitution from "../Constitution";
import { permissionService } from "../../services/permissionService";
import Link from "../link";
import { Article, Nations, NationStat, Role } from "../../my-api-client";
import useSWR from "swr";
import { apiClient } from "../../services/apiClient.ts";
import List from "@mui/material/List";
import {
    Box,
  Accordion, AccordionDetails, AccordionSummary,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  ListItem,
  ListItemText,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import NationStatsDisplay, { Stat } from "./nationStatsDisplay.tsx";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import NationPowerCardsDisplay from "./nationPowerCardsDisplay.tsx";
import NationName from "./nationName.tsx";
import GeoWrapperTooltip from "../GeoWrapperTooltip";
import {alpha} from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Joyride from "react-joyride";
import JoyrideWrapper from "../Joyridewrapper";

export default function NationContent({
                                        index,
                                        nation,
                                        stats,
                                        adminOpen,
                                        setAdminOpen,
                                        resourcesOpen,
                                        setResourcesOpen,
                                        statsOpen,
                                        setStatsOpen,
                                        powerOpen,
                                        setPowerOpen
                                      }: {
    index: number,
  nation: Nations,
  stats: NationStat,
  adminOpen: boolean,
  setAdminOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  resourcesOpen: any,
  setResourcesOpen: any,
  statsOpen: any,
  setStatsOpen: any,
  powerOpen: any,
  setPowerOpen: any
}) {
    const [nationColor, setNationColor] = React.useState<number[]>([255, 255, 255]);
  const user = useUser();
  const { data: roles, error } = useSWR<Role[], Error>(
    apiClient().allRolesList.name,
    function () {
      return apiClient()
        .allRolesList()
        .then((res) => {
          return res.data;
        });
    },
  );

  const nationStats2: Stat[] = [];

  for (let i = 0; i < stats?.stats?.length || 0; i++) {
    nationStats2.push({
      title: stats.stats[i].name,
      value: stats.stats[i].displayValue,
      progress: stats.stats[i].value,
        description: stats.stats[i].description,
    });
  }

  if (!user) {
    return <LoadingScreen />;
  }

    // Define the role order
    const roleOrder = ["Prime Minister", "Defense Minister", "Chief Financial Officer", "Secretary of State", "Comptroller"];

// Function to get the role index based on the defined order
    const getRoleOrderIndex = (role: Role) => roleOrder.indexOf(role.name);

// Sort the users based on the role order
    const sortedUsers = nation.users.sort((a, b) => {
        // Find the first role in the user's list that matches the role order
        const aRoleIndex = a.roles.map(getRoleOrderIndex).find(index => index !== -1) || Infinity;
        const bRoleIndex = b.roles.map(getRoleOrderIndex).find(index => index !== -1) || Infinity;

        return aRoleIndex - bRoleIndex;
    });

    let steps = [
        {
            target: '#flag_' + nation.id,
            content: 'The comptroller can upload the nations flag',
        },
        {
            target: '#nation_name_' + nation.id,
            content: 'The comptroller can add their nations name',
        },
        {
            target: '#constitution_' + nation.id,
            content: 'You can view a nations constitution and the comptroller can write their nations constitution',
        },
        {
            target: '#administration_' + nation.id,
            content: 'The members and roles for each nation',
        },
        {
            target: '#production_' + nation.id,
            content: 'As you build infrastructure, your production will increase and display your next production values here',
        },
        {
            target: '#consumption_' + nation.id,
            content: 'As you build infrastructure you may also have daily costs',
        },
        {
            target: '#stats_' + nation.id,
            content: 'These are the current stats for each nation which are influenced by actions taken by your nation',
        },
        {
            target: '#power_points_' + nation.id,
            content: 'There are many ways each nation can gain points.',
        },
    ];
    if (user.isAdmin) {
        steps.splice(7,0, {
            target: '#population_stat_' + nation.id,
            content: 'You can add events here which will affect the population of this nation',
        })
    }

    if (user.nation.id != nation.id) {
        if (user.nation.name == "World Bank" && index == 0) {
            // keep
        } else {
            steps = [];
        }
    }

  return (
    <>
        <JoyrideWrapper steps={steps} id={"nation_ride"} />
      <Card
        sx={{ maxWidth: 800, margin: "auto", width: "100%", backgroundColor: nationColor ? alpha(`rgb(${nationColor[0]}, ${nationColor[1]}, ${nationColor[2]})`, .15) : "white" }}
        variant="outlined"
      >
        <Box >
        <ImageBox
            id={"flag_" + nation.id}
          image={nation.imageUrl}
          field={"flag"}
          upload={`nations/${nation.id}/`}
          canEdit={permissionService.canUploadFlag(user, nation).success}
          circle={false}
            setNationColor={setNationColor}
        />
        </Box>
        <CardContent>
          <NationName nation={nation} id={"nation_name_" + nation.id} />

            <Link href={`/app/nations/${nation.id}/constitution`} id={"constitution_" + nation.id}>
                <Typography variant="body2" component="div" sx={{ display: 'flex', alignItems: 'center', padding: '10px 0px'}}>
                    Constitution <ArticleOutlinedIcon sx={{ marginLeft: "10px"}} />
                </Typography>
            </Link>

            <Typography variant="h6" gutterBottom id={"administration_" + nation.id}>Administration</Typography>

          <Typography variant="body2" component="div" gutterBottom>


          <List disablePadding>
            {sortedUsers
              .map((user) => {
                return (
                  <ListItem key={user.id} sx={{ pl: 0 }} disablePadding>
                    <Link
                      href={`/app/profile/${user.id}`}
                      css={css`
                        cursor: pointer;
                        display: flex;
                        font-weight: 500;
                      `}
                    >
                        <Box sx={{ display: 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'padding': '8px 16px'}}>
                            <Avatar sx={{ width: 32, height: 32, marginRight: "10px" }} src={user.imageUrl} />
                            <Box sx={{ display: 'flex', 'flexDirection': 'column'}}>
                                <Typography variant="body1">{user.fullName}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    { user.roles.map((userRole) => {
                                        return userRole.name
                                    }).join(", ")}
                                </Typography>
                            </Box>
                        </Box>


                    </Link>
                  </ListItem>
                );
              })}
          </List>


          </Typography>
            <Typography variant="h6" gutterBottom id={"resources_" + nation.id}>Resources</Typography>


          <Typography variant="body2" component="div">
            {nation.resources && nation.resources.length ? (
              <List>
                {nation.resources.map((resource) => {
                  return (
                    <ListItem key={resource.id}>
                      <ListItemIcon>
                        <img
                          width={20}
                          height={20}
                          alt="image"
                          src={`${import.meta.env.VITE_CDN}${
                            resource.resourceType.icon
                          }`}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={resource.resourceType.name}
                        secondary={`${resource.total}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              ""
            )}
          </Typography>

            <Typography variant="h6" gutterBottom id={"production_" + nation.id}>Production</Typography>


            <Typography variant="body2" component="div">
                <List>
                    {stats?.production?.map((resource) => {
                        return (
                            <ListItem key={resource.name}>
                                <ListItemIcon>
                                    <img
                                        width={20}
                                        height={20}
                                        alt="image"
                                        src={`${import.meta.env.VITE_CDN}${
                                            resource.icon
                                        }`}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={resource.name}
                                    secondary={`+${resource.total}`}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Typography>

            <Typography variant="h6" gutterBottom id={"consumption_" + nation.id}>Consumption</Typography>


            <Typography variant="body2" component="div">
                    <List>
                        {stats?.consumption?.map((resource) => {
                            return (
                                <ListItem key={resource.name}>
                                    <ListItemIcon>
                                        <img
                                            width={20}
                                            height={20}
                                            alt="image"
                                            src={`${import.meta.env.VITE_CDN}${
                                                resource.icon
                                            }`}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={resource.name}
                                        secondary={`${resource.total}`}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
            </Typography>


            <Typography variant="h6" gutterBottom id={"stats_" + nation.id}>Nation Stats</Typography>


                    <NationStatsDisplay nationId={nation.id} stats={nationStats2} />

            <Typography variant="h6" gutterBottom id={"power_points_" + nation.id}>Power Points</Typography>


          <NationPowerCardsDisplay nationStat={stats} />
          <List>
                <ListItem divider sx={{ justifyContent: "space-between" }}>
                    <GeoWrapperTooltip title="The points from all the infrastructure controlled on the map" placement="top">
                        <ListItemText primary={`Points From Infrastructure: ${stats.buildingPoints}`} />
                    </GeoWrapperTooltip>
                </ListItem>
            <ListItem divider sx={{ justifyContent: "space-between" }}>
              <ListItemText primary={`Total Power Points: ${stats.powerPoints}`} />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  );
}
