import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import Development from "./index";
import Pending from "./pending";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Approved from "./approved.tsx";
import Declined from "./declined.tsx";
import Military from "./military.tsx";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";


export default function BasicTabs() {
  const [queryParams, setQueryParams] = useSearchParams();
    const [value, setValue] = React.useState("infrastructure");

    useEffect(() => {
        const tab = queryParams.get("tab") || "infrastructure";
        // @ts-ignore
        setValue(tab);
    }, [queryParams]);


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
      setQueryParams({ tab: newValue });
  };

  return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center'  }}>
        <TabList
          onChange={handleChange}
          aria-label="development sections"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="Infrastructure" value={"infrastructure"} className={"infrastructure_header"} />
          <Tab label="Military" value={"military"} className={"military_header"} />
          <Tab label="Pending" value={"pending"} className={"pending_header"} />
          <Tab label="Approved" value={"approved"}  />
          <Tab label="Declined" value={"declined"}  />
        </TabList>
      </Box>
      <TabPanel value={'infrastructure'}>
        <Development />
      </TabPanel>
      <TabPanel value={'military'}>
        <Military />
      </TabPanel>
      <TabPanel value={'pending'}>
        <Pending />
      </TabPanel>
      <TabPanel value={'approved'}>
        <Approved />
      </TabPanel>
      <TabPanel  value={'declined'}>
        <Declined />
      </TabPanel></TabContext>
    </Box>
  );
}
