/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useSWR, { Fetcher } from "swr";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { mutateUser, myMutate, useUser } from "../../hooks/useUser";
import {
  AdminNations,
  PaginatedAdminClassNameList,
  PaginatedAdminNationsList,
} from "../../my-api-client";
import { adminApiClient, apiClient } from "../../services/apiClient";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { useEffect, useState } from "react";
import { permissionService } from "../../services/permissionService";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import GeoTooltip from "../GeoTooltip";

interface AdminHeaderProps {
  menuOpen: boolean;
}

export default function AdminHeader({ menuOpen }: AdminHeaderProps) {
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const [adminOn, setAdminOn] = useState(permissionService.getAdminOn());
  const [className, setClassName] = useState<number>(user.className.id);
  const [nation, setNation] = useState<number>(user.nation.id);
  const [classNations, setClassNations] = useState<AdminNations[]>();

  const classFetcher: Fetcher<PaginatedAdminClassNameList> = () => {
    return adminApiClient()
      .drfadminClassesList()
      .then((res) => {
        return res.data;
      });
  };
  const nationsFetcher: Fetcher<PaginatedAdminNationsList> = () => {
    return adminApiClient()
      .drfadminNationsList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: nations, error } = useSWR<PaginatedAdminNationsList, Error>(
    adminApiClient().drfadminNationsList.name,
    nationsFetcher,
  );

  const { data: classes, error: classesError } = useSWR<
    PaginatedAdminClassNameList,
    Error
  >(adminApiClient().drfadminClassesList.name, classFetcher);

  useEffect(() => {
    setClassNations(nations?.results?.filter((n) => n.className == className));
  }, [className, nations]);

  const updateUser = () => {
    if (user) {
      const snack = enqueueSnackbar(
        <>
          Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <CircularProgress />
        </>,
        {
          variant: "info",
          persist: true,
        },
      );
      setSaving(true);
      adminApiClient()
        .drfadminUsersPartialUpdate(user.id, {
          className: className,
          nation: nation,
        })
        .then((r) => {
          mutateUser();
          myMutate(apiClient().allTradesList.name);
          enqueueSnackbar("Admin options updated", {
            variant: "success",
          });
          location.reload();
        })
        .catch((e) => {
          enqueueSnackbar("Error updating admin options: " + e.toString(), {
            variant: "error",
          });
        })
        .finally(() => {
          setSaving(false);
          closeSnackbar(snack);
        });
    }
  };

  const updateNation = (event: SelectChangeEvent) => {
    setNation(parseInt(event.target.value));
  };

  const updateClass = (event: SelectChangeEvent) => {
    setClassName(parseInt(event.target.value));
    setNation(nations?.results?.find((n) => n.className == parseInt(event.target.value) && n.isWorldBank)?.id || 0);
  };

  return (
    <>
      {menuOpen && (
        <div
          css={css`
            color: red;
            background: lightgray;
            height: 80px;
            width: 100%;
            display: flex;
            align-items: center;
            position: fixed;
            z-index: 2;
          `}
        >
          <FormControl sx={{marginLeft: "10px"}}>
            <InputLabel id="demo-simple-select-label">Class Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={className.toString()}
              label="Class Select"
              onChange={updateClass}
            >
              {classes?.results?.map((className) => {
                return (
                  <MenuItem key={className.id} value={className.id}>
                    {className.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: "15px" }}>
            <InputLabel id="demo-simple-select-label">Nation Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={nation.toString()}
              label="Nation Select"
              onChange={updateNation}
            >
              {classNations?.map((nation) => {
                return (
                  <MenuItem key={nation.id} value={nation.id}>
                    {nation.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {(user.nation.id != nation || user.className.id != className) && (
            <Button
              style={{ marginLeft: "15px" }}
              variant="contained"
              onClick={updateUser}
              disabled={
                classNations?.find((n) => n.id == nation) === undefined ||
                saving
              }
            >
              Save
            </Button>
          )}

          <FormControl
            component="fieldset"
            variant="standard"
            style={{ marginLeft: "15px" }}
          >
            <FormGroup>
              <FormControlLabel
                  sx={{color: "#000000de"}}
                control={
                  <Switch
                    checked={!adminOn}
                    onChange={() => {
                      setAdminOn(!adminOn);
                      permissionService.setAdminOn(!adminOn);
                    }}
                    name="adminenabled"
                  />
                }
                label="Disable Admin"
              />
            </FormGroup>
          </FormControl>
          <GeoTooltip
            title={
              "As an admin you often have options which may not be available to other users.  " +
                "This switch will disable those options so you can view the site as a regular user."
            }
        />
        </div>
      )}
    </>
  );
}
