/** @jsxImportSource @emotion/react */
import Comments from "../comments";
import AddComment from "../addComment";
import EditArticle from "../editArticle.tsx";
import { Article } from "../../../my-api-client";
import { Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "../../link";
import React, { useState } from "react";
import { Box } from "@mui/material";
import {css} from "@emotion/react";


export default function ArticleComponent({ article }: { article: Article }) {
  const [editing, setEditing] = useState(false);

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", mt: 5, width:  {
            xs: '100%',
            sm: '100%',
            md: '800px',
            lg: '800px',
            xl: '800px',
        }  }}>
      <Box sx={{ background: "transparent", width: '100%' }}>
        {!editing && (
          <>
            <Link
              href={`/app/news/${article.id}`}
              color="inherit"
              disabled={article.id === 0}
            >


                <span
                    css={css`
                      font-weight: 700;
                      font-size: 30px;
                      text-transform: uppercase;
                      padding: 10px 0 10px 0;
                      text-align: center;
                      line-height: normal;
                      font-family: 'Playfair Display', serif;
                      display: block;
                      margin: 0 auto;
                      color: #2f2f2f;
      `}
                  >
                    {article.title}
                </span>

            </Link>

              <Link href={`/app/profile/${article.createdBy.id}`}
                    disabled={article.id === 0 || article.createdBy.fullName.toLowerCase() === 'geostrat'}
              >
                  <p
                  css={css`
                    margin-top: 0;
                    margin-bottom: 20px;
                  `}
                  ><span

                      css={css`
                        font-weight: 700;
                        font-size: 12px;
                        box-sizing: border-box;
                        padding: 10px 0;
                        text-align: center;
                        line-height: normal;
                        font-family: 'Playfair Display', serif;
                        display: block;
                        margin: 0 auto;
                        color: #2f2f2f;
                        &::before {
                          border-top: 1px solid #2f2f2f;
                          content: '';
                          width: 250px;
                          height: 7px;
                          display: block;
                          margin: 0 auto;
                        };
                        &::after {
                        border-bottom: 1px solid #2f2f2f;
                        content: '';
                        width: 250px;
                        height: 10px;
                        display: block;
                        margin: 0 auto;
                      };
                        
      `}>by {article.createdBy.fullName.toUpperCase()}</span></p>

              </Link>

              {
                  article.id == 0 ?
                <Box
                  component="p"
                  sx={{ mt: 2, mb: 0, whiteSpace: "pre-wrap", fontFamily: "Playfair Display, serif"}}
                  dangerouslySetInnerHTML={{
                      __html: article.content,
                  }}
                ></Box> : <Box
                          component="p"
                          sx={{ mt: 2, mb: 0, whiteSpace: "pre-wrap", fontFamily: "Playfair Display, serif"}}

                      >
                          {article.content}
                      </Box>
              }
            {" "}
          </>
        )}
          {
              article.id !== 0 && (<>
                  <EditArticle
                      article={article}
                      editing={editing}
                      setEditing={setEditing}
                  />
              <AddComment article={article} />
        <Comments comments={article.comments} />
                  </>
              )
          }
      </Box>
    </Box>
  );
}
